import React from "react"
import { RecoilRoot } from "recoil"
import { Helmet } from "react-helmet"

export const onPreRouteUpdate = () => {
  document.body.scrollTop = 0
}

export const wrapRootElement = ({ element }) => {
  return (
    <RecoilRoot>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <script>
          var clicky_site_ids = clicky_site_ids || [];
          clicky_site_ids.push(101147029);
        </script>
        <script async src="//static.getclicky.com/js"></script>
        <script type="text/javascript">
          {`
                var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/5f7e69ab4704467e89f5978f/default';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
                })();  `}
        </script>
      </Helmet>

      {element}
    </RecoilRoot>
  )
}
